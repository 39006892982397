import React from 'react'
import { connect } from 'react-redux'

import Layout from '../../../../components/Layout'
import BrandTabs from '../../../../components/marketplace/brand/BrandTabs'

class Demographics extends React.Component {
  render() {
    return (
      <Layout noFooter active="brand">
        <BrandTabs active="demographics" />
        <iframe
          title="brand_infogram"
          src="https://infogram.com/demographics-1h984w7dvmzv2p3?live"
          className="full-page-iframe"
        />
      </Layout>
    )
  }
}

export default connect(
  state => ({
    brandTrackerLink: state.brandReports.trackInfogram,
  }),
  {}
)(Demographics)
